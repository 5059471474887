import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
/* import { reducer as notifications } from 'react-notification-system-redux' */


import { globalDataReducer, globalDataReducerInitialState } from 'src/redux/globalData/globalDataReducer';
import { plantReducer, plantReducerInitialState } from 'src/redux/plantData/plantReducer';
import { serviceReducer, serviceReducerInitialState } from 'src/redux/serviceData/serviceReducer';
import { turnReducer, turnReducerInitialState } from 'src/redux/turnData/turnReducer';
import { paymentReducer, paymentReducerInitialState } from 'src/redux/paymentData/paymentReducer';





const createRootReducer = history => {
	const appReducer = combineReducers({
		router: connectRouter(history),
		globalDataReducer,
		plantReducer,
		serviceReducer,
		turnReducer,
		paymentReducer

	});
	return (state, action) => {
		/* if (action.type === LOGOUT) {
			state.globalDataReducer = globalDataReducerInitialState;
			state.plantReducer = plantReducerInitialState;
			state.paymentReducer = paymentReducerInitialState
		} */
		return appReducer(state, action);
	}
};

export default createRootReducer;
