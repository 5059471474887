import { CButton } from '@coreui/react'
import React from 'react'
import { ModalBody, ModalFooter, ModalHeader, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
const ModalTerms = ({ onHide, ...rest }) => {
  return (
    <Modal size="lg" {...rest} onHide={onHide} backdrop="static" keyboard={false}>
      <ModalHeader className="d-flex justify-content-center">
        <p>Términos y condiciones</p>
      </ModalHeader>

      <ModalBody>
        <ol>
          <li className="mt-2">
            Una vez generado el turno, le solicitamos que este 5 minutos antes del horario pactado.
          </li>
          <li className="mt-2">Su turno tendrá una tolerancia de 15 minutos de espera.</li>
          <li className="mt-2">
            En caso que no cumpla con los requisitos formales para el trámite se le indicara los
            pasos a seguir y se le puede reporgramar un nuevo turno.
          </li>
          <li className="mt-2">
            En caso que haya abonado un servicio distinto al que necesita, deberá realizar su
            reclamo con el comprobante de pago, el cual se le reintegrará el dinero en un plazo de
            96 hs.
          </li>
          <li className="mt-2">
            Los reclamos por devoluciones de pago u otros servicios, no interfiere con el turno
            solicitado.
          </li>
          <li className="mt-2">
            Cualquier inconveniente o consulta comuníquese al teléfono fijo 376 - 447430 de 7 a 13
            hs.
          </li>
        </ol>
      </ModalBody>
      <ModalFooter>
        <CButton onClick={() => onHide()}>Cerrar</CButton>
      </ModalFooter>
    </Modal>
  )
}
ModalTerms.propTypes = {
  onHide: PropTypes.func.isRequired,
}
export default ModalTerms
